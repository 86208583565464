<template>
    <div class="control">

        <img src="https://keyang2.tuzuu.com/uuo/ydj/e2b46b4ebc393780a2e3e76a0b7f7b68.png" class="start-img"/>

        <div class="control-footer">
            <img src="https://keyang2.tuzuu.com/uuo/ydj/d29b67708a9c2c477595e22525f4fa37.png" class="footer-img"/>
            <div class="start-btn" v-show="show" @click="href">
                开启健康生活
            </div>
        </div>
    </div>
</template>

<script>
import wxShare from "@/wechat/weChatTile";
import qs from "qs"

export default {
    data() {
        return {
            show: false
        }
    },
    async created() {
        let query = qs.stringify(this.$route.query)
        query = query ? `?${query}` : ''
        const res = await axios.get('/tyqyk/check_is_activation')
        if (res.data.is_activation === 1) {
            await this.$router.replace(`/new/ydj/person-interests${query}`)
        } else {
            this.show = true
        }
    },
    mounted() {
        let query = qs.stringify(this.$route.query)
        query = query ? `?${query}` : ''
        wxShare({
            title: '一站式健康管理服务平台',
            desc: '康来家园，中国健康管理领导者',
            link: window.location.origin + `/new/ydj${query}`,
        })
    },
    methods:{
        href(){
            let query = qs.stringify(this.$route.query)
            query = query ? `?${query}` : ''
            this.$router.push(`/new/ydj/active${query}`)
        }
    }
}


</script>

<style scoped lang="less">
.control {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .start-img {
        width: 90%;
        margin: 25% auto 0;
    }

    .control-footer {
        position: relative;

        .footer-img {
            width: 100%;
            display: block;
        }

        .start-btn {
            position: absolute;
            width: fit-content;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: fit-content;
            margin: auto;
            background: #327F5C;
            border-radius: 50px;
            border: 1px solid #FFFFFF;
            font-size: 19px;
            color: #FFFFFF;
            letter-spacing: 1px;
            padding: 7px 36px;
        }
    }
}
</style>